<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车辆调动</el-breadcrumb-item>
      <el-breadcrumb-item>调度审核</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <!-- 进程明细对话框 -->
      <el-dialog title="进程明细" :visible.sync="jinVisible" width="30%" :before-close="handleClose">
        <el-form :model="jin_info" label-width="100px">
          <el-form-item label="申请时间">
            <el-input v-model="jin_info.start_time_at" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调出项目名称">
            <el-input v-model="jin_info.old_org_name" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调入项目名称">
            <el-input v-model="jin_info.current_org_name" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调配车辆类型">
            <el-input v-model="jin_info.use_type_name" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="调配车辆牌号">
            <el-input v-model="jin_info.auto_number" autocomplete="off" disabled></el-input>
          </el-form-item>
          <el-form-item label="申请状态">
            <el-input v-model="jin_info.status_name" autocomplete="off" disabled></el-input>
          </el-form-item>
        </el-form>

        <el-collapse v-model="activeNames">
          <el-collapse-item v-for="(item, index) in logs" :key="index" :name="item.id" :title="item.created_at">
            <div>{{ item.remark }}</div>
          </el-collapse-item>
        </el-collapse>
        <div slot="footer" class="dialog-footer">
          <el-button @click="jinVisible = false">取 消</el-button>
          <el-button type="primary" @click="jinVisible = false">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 管理员审核同意对话框 -->
      <el-dialog title="确定同意车辆调出" :visible.sync="dialogVisible" width="40%" :before-close="handleClose">
        <el-form ref="dform" :model="dform" label-width="100px" :rules="drules">
          <!-- 调入项目名称 -->
          <el-form-item label="调出项目名称" prop="old_org" label-width="150px">
            <el-select v-model="dform.old_org">
              <el-option v-for="item in trashtypeData" :key="item.id" :label="item.node_name" :value="item.id" @click.native="choicedrug(item)"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelfun(dform)">取 消</el-button>
          <el-button type="primary" @click="submitForm('dform')">确 定</el-button>
        </span>
      </el-dialog>

      <!-- 项目审核同意 -->
      <el-dialog title="确定同意车辆调出" :visible.sync="xiangmudiao" width="40%" :before-close="handleClose">
        <el-form ref="dformxiang" :model="dformxiang" label-width="100px">
          <el-form-item label="选择车辆" prop="key">
            <el-autocomplete
              :trigger-on-focus="false"
              placeholder="请输入车牌号"
              v-model="key"
              :fetch-suggestions="salesperson"
              value-key="auto_number"
              @select="XiaohandleSelect($event, '车牌号')"
            ></el-autocomplete>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancelfuns(dformxiang)">取 消</el-button>
          <el-button type="primary" @click="submitFormss('dformxiang')">确 定</el-button>
        </span>
      </el-dialog>

      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="start_time_at" label="申请日期" width="180"></el-table-column>
        <el-table-column prop="hope_time_at" label="期望完成日期" width="180"></el-table-column>
        <el-table-column prop="auto_number" label="车牌号" width="180">
          <template slot-scope="scope">
            <span>{{ scope.row.auto_number ? scope.row.auto_number : '待确定' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="use_type_name" label="调配车类型" width="200"></el-table-column>
        <el-table-column prop="current_org_name" label="调入项目名称" width="200"></el-table-column>
        <el-table-column prop="status_name" label="申请状态" width="180"></el-table-column>
        <el-table-column prop="status" label="操作" width="350">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" @click="jincheng(scope.row.id)">进程明细</el-button>
            <el-button v-if="falg && scope.row.status == 0" size="mini" type="success" @click="ok(scope.row.id)">同意</el-button>
            <el-button v-if="falgs && scope.row.status == 1" size="mini" type="warning" @click="okss(scope.row.id)">同意</el-button>
            <el-button v-if="falg && scope.row.status == 0" size="mini" type="danger" @click="jujueguan(scope.row)">拒绝</el-button>
            <el-button v-if="falgs && scope.row.status == 1" size="mini" type="danger" @click="jujuexiang(scope.row)">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />
    </el-card>
  </div>
</template>

<script>
import Fenye from '../../../components/Fenye'
import { Getxiangmu, Getdiaolie, Getjin, Guanliyuan, Xiangmushen, GetautoList } from '../../../api/car'
export default {
  components: {
    Fenye
  },
  data() {
    return {
      xiangmudiao: false,
      wangfeng: '18561321433',
      cao: '17854260201',
      falg: false,
      falgs: false,
      trashtypeData: [],
      dialogVisible: false,
      logs: [], // 详情数据下半部分
      jinVisible: false, // 进程明细对话框
      tableData: [],
      key: '',
      jinchengid: '', // 进程id
      jin_info: {}, // 详情数据上半部分
      activeNames: ['1'],
      userlist: [],
      jujueguan_id: '',
      juguanold_orgid: '',
      jujuexiang_id: '',
      juxiangold_orgid: '',
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      show_type: '',
      dform: {
        auth: 'admins',
        id: '',
        old_org: '', // 调出项目
        audit_status: 1
      },
      dformxiang: {
        id: '',
        auto_id: '',
        audit_status: 1
      },
      drules: {
        old_org: [{ required: true, message: '请选择项目', trigger: 'change' }]
      }
    }
  },
  created() {
    let caaa = localStorage.getItem('ming')
    if (caaa == this.wangfeng) {
      this.falg = true
      this.falgs = false
      this.show_type = 0
    } else if (caaa == this.cao) {
      this.falg = true
      this.falgs = false
      this.show_type = 0
    } else {
      this.falgs = true
      this.show_type = 1
    }
  },
  mounted() {
    this.Getdiaolist() // 拉取列表
  },
  methods: {
    // 拒绝
    jujueguan(e) {
      console.log(e)
      this.jujueguan_id = e.id
      this.juguanold_orgid = e.old_org
      this.$confirm('确认拒绝, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Endjujueguan()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消拒绝'
          })
        })
    },
    jujuexiang(e) {
      console.log(e)
      this.jujuexiang_id = e.id
      this.juxiangold_orgid = e.old_org
      this.$confirm('确认拒绝, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.Endjujuexiang()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消拒绝'
          })
        })
    },
    // 同意
    okss(e) {
      this.dformxiang.id = e
      console.log(this.dformxiang.id)
      this.xiangmudiao = true
    },
    // 管理员同意
    ok(e) {
      console.log(e)
      this.dform.id = e
      this.dialogVisible = true
      this.Getxiangmulist(this.dform)
    },
    // 选中获取项目id
    choicedrug(e) {
      this.dform.old_org = e.id
      console.log(this.dform.old_org)
    },
    // 输入车辆匹配数据
    salesperson(queryString, cb) {
      this.getallfun(queryString, cb)
    },
    // 搜索车辆选中
    XiaohandleSelect(e, term) {
      this.dformxiang.auto_id = e.id
      console.log(this.dformxiang.auto_id)
    },
    // 同意取消
    cancelfun() {
      this.$refs.dform.resetFields()
      this.dialogVisible = false
    },
    // 同意取消
    cancelfuns() {
      this.key = ''
      this.xiangmudiao = false
    },
    // 同意申请
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 成功
          this.Guanliyuanok(this.dform) // 添加用户信息
          this.$refs[formName].resetFields()
          this.Getdiaolist()
          this.dialogVisible = false
        }
      })
    },
    // 同意申请
    submitFormss(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 成功
          this.Xiangmushenya(this.dformxiang) // 添加用户信息
          this.$refs[formName].resetFields()
          this.Getdiaolist()
          this.xiangmudiao = false
        }
      })
    },
    // 进程明细
    jincheng(e) {
      console.log(e)
      this.jinchengid = e
      this.Getjincheng()
      this.jinVisible = true
    },
    // 关闭添加弹窗
    handleClose() {
      this.jinVisible = false
      this.xiangmudiao = false
      this.dialogVisible = false
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getdiaolist()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Getdiaolist()
    },

    // 调度申请列表
    async Getdiaolist() {
      const { data } = await Getdiaolie({ show_type: this.show_type, status: 2, ...this.pageData })
      this.tableData = data.data.list
      // console.log(this.tableData)
      this.pageData.count = data.data.count
      this.pageData.page = data.data.page * 1
      this.getanpostfun(data, '获取')
    },

    // 进程
    async Getjincheng() {
      const { data } = await Getjin({ id: this.jinchengid })
      console.log(data)
      this.jin_info = data.data.info
      this.logs = data.data.logs
      console.log(this.logs)
    },

    // 获取项目
    async Getxiangmulist() {
      const { data } = await Getxiangmu()
      this.trashtypeData = data.data
    },
    // 管理员同意
    async Guanliyuanok(v) {
      const { data } = await Guanliyuan(v)
      this.getanpostfun(data, '同意', this.Getdiaolist())
    },
    // 管理员拒绝
    async Endjujueguan() {
      const { data } = await Guanliyuan({ id: this.jujueguan_id, audit_status: 2, old_org: this.juguanold_orgid })
      this.getanpostfun(data, '拒绝', this.Getdiaolist())
    },
    // 项目同意
    async Xiangmushenya(v) {
      const { data } = await Xiangmushen(v)
      this.getanpostfun(data, '同意', this.Getdiaolist())
    },
    // 项目拒绝
    async Endjujuexiang() {
      const { data } = await Xiangmushen({ id: this.jujuexiang_id, audit_status: 2, old_org: this.juxiangold_orgid })
      this.getanpostfun(data, '拒绝', this.Getdiaolist())
    },
    // 获取车辆
    async getallfun(key, cd) {
      const { data } = await GetautoList({ key: key })
      this.userlist = data.data.list
      cd(data.data.list)
    },

    // 公共方法区└(^o^)┘└(^o^)┘
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        // console.log(text)
        if (text !== '获取' && text !== '删除') {
          // console.log(123)
          this.handleClose()
        }

        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style>
</style>